import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

// Opinionated CSS reset!
import "sanitize.css/sanitize.css";

import Signup from "./signup";
import Header from "./header";
import Footer from "./footer";
import "./layout.scss";

// Passing an empty tag <> is an alias for React.Fragment and allows multiple
// child elements to be returned instead of requiring a single parent element
const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        prismicHomepage {
          data {
            title {
              text
            }
            description {
              text
            }
            image {
              url
              localFile {
                relativePath
              }
              dimensions {
                width
                height
              }
            }
          }
        }
      }
    `}
    render={data => (
      <>
        {/* Use homepage data as default metadata */}
        <Helmet
          title={data.prismicHomepage.data.title.text}
          meta={[
            {
              name: "description",
              content: data.prismicHomepage.data.description.text,
            },
            {
              property: "og:description",
              content: data.prismicHomepage.data.description.text,
            },
            {
              property: "og:title",
              content: data.prismicHomepage.data.title.text,
            },
            {
              property: "og:image",
              content: data.prismicHomepage.data.image.url,
            },
            {
              property: "og:image:alt",
              content: data.prismicHomepage.data.description.text,
            },
            {
              property: "og:image:width",
              content: data.prismicHomepage.data.image.dimensions.width,
            },
            {
              property: "og:image:height",
              content: data.prismicHomepage.data.image.dimensions.height,
            },
            {
              property: "og:locale",
              content: "en_US",
            },
            {
              property: "og:type",
              content: "website",
            },
            {
              property: "og:site_name",
              content: "ProCliq",
            },
            {
              name: "twitter:title",
              content: data.prismicHomepage.data.title.text,
            },
            {
              name: "twitter:description",
              content: data.prismicHomepage.data.description.text,
            },
            {
              name: "twitter:image",
              content: data.prismicHomepage.data.image.url,
            },
            {
              name: "p:domain_verify",
              content: "619978391f017a34fb434e901f6f29f1",
            },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Signup />
        <Header />
        <div className="layout content">{children}</div>
        <Footer />
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
