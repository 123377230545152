import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import "./footer.scss";

import imgLogoWhite from "../images/procliq_logo_white.svg";
import imgFacebook from "../images/facebook.svg";
import imgTwitter from "../images/twitter.svg";
import imgInstagram from "../images/instagram.svg";
// import imgYouTube from "../images/youTube.svg";

class Footer extends React.PureComponent {
  state = {
    // Always get a fresh year value when class is instantiated
    year: new Date().getFullYear(),
  };

  render() {
    const { year } = this.state;
    return (
      <footer>
        <div className="social">
          <OutboundLink
            href="https://www.facebook.com/procliqapp/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={imgFacebook} alt="Facebook" />
          </OutboundLink>
          <OutboundLink
            href="https://twitter.com/procliqapp/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={imgTwitter} alt="Twitter" />
          </OutboundLink>
          <OutboundLink
            href="https://www.instagram.com/productioncliq/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={imgInstagram} alt="Instagram" />
          </OutboundLink>
        </div>
        <div className="logo">
          <img src={imgLogoWhite} alt="ProductionCliq Logo" />
          <div className="copyright">Copyright {year}, ProductionCliq, LLC</div>
        </div>
      </footer>
    );
  }
}

export default Footer;
