import React from "react";
import { Link } from "gatsby";
import { openSignupPage } from "../helpers";
import "./cta.scss";

import imgLogoDark from "../images/procliq_logo_gray.svg";

const CTA = () => (
  <div className="cta">
    <img src={imgLogoDark} alt="ProCliq Logo" />
    <div className="message">
      Be one of the first to get early access to the free beta.
      <br />
      Request access now!
    </div>
    <div className="buttons">
      <button onClick={openSignupPage}>Get Access Today</button>
    </div>
  </div>
);

export default CTA;
