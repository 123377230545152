import React from "react";
import { Link } from "gatsby";
// import { OutboundLink } from "gatsby-plugin-google-analytics";
import logo from "../images/procliq_logo_gray.svg";
import "./header.scss";

const Header = () => (
  <header>
    <div className="content">
      <Link to="/">
        <img className="topLogo" src={logo} alt="ProCliq Logo" />
      </Link>
      <div className="menu">
        {/*
            <Link
              className="menuItem"
              to="/blog/"
            >
              Blog
            </Link>
        <OutboundLink
          className="menuItem"
          target="_blank"
          rel="noopener noreferrer"
          href="https://app.procliq.com/signin"
        >
          Sign In
        </OutboundLink>
        */}
        <button
          type="button"
          className="menuItem signUpButton prefinery-form-cta"
        >
          Get Early Access
        </button>
      </div>
    </div>
  </header>
);

export default Header;
