import React, { PureComponent } from "react";
// import qs from "qs";
import { closeSignupPage } from "../helpers";
import imgClose from "../images/close-24px.svg";
import "./signup.scss";

class Signup extends PureComponent {
  // state = {
  //   referralCode: {},
  // };

  // componentDidMount() {
  //   const referralCode =
  //     window && window.location && window.location.search
  //       ? qs.parse(window.location.search.split("?")[1])
  //       : {};

  //   if (referralCode)
  //     this.setState({
  //       referralCode,
  //     });
  // }

  render() {
    // const body = this.state.referralCode.r
    //   ? `Please%2C%20put%20me%20on%20the%20waiting%20list%20to%20try%20out%20the%20beta!%0A%0AReferral%20Code%3A%20${
    //       this.state.referralCode.r
    //     }`
    //   : `Please%2C%20put%20me%20on%20the%20waiting%20list%20to%20try%20out%20the%20beta!`;

    return (
      <div id="signup" className="signup">
        <div className="content">
          <button className="close" onClick={closeSignupPage}>
            <img alt="close" src={imgClose} />
          </button>
          <div className="prefinery-form-embed">
            {/* Fallback content included in case Prefinery doesn't load */}
            {/*
              Thanks for your interest!
                <br />
                Please email us at
                <br />
                <br />
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href={`mailto:beta-signup@procliq.com?Subject=Beta%20Access%20Request&Body=${body}`}
                >
                  beta-signup@procliq.com
                </a>
                */}
          </div>
        </div>
      </div>
    );
  }
}

export default Signup;
